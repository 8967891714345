<template>
  <component :is="'b-card'">

    <b-tabs pills>

      <!-- Tab: Müşteri Hesap -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">
             {{ $t('Müşteri Hesap') }}
            </span>
        </template>
        <musteri-detail
          :musteri-data="musteriData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Kullanıcılar -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">
             {{ $t('Müşteri Kullanıcıları') }}
            </span>
        </template>
        <b-card no-body>
          <div class="">
            <b-row>
              <!-- Create -->
              <b-col
                cols="12"
                md="12"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-button @click="$router.push({ name: 'manage-kullanici-save', params: { id: -1, musteri_id: musteriData.id } })"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span class="align-middle">
                       {{ $t('Yeni Kullanıcı Ekle') }}
                      </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <kullanici-list 
            :showButtons="false" 
            :showFilter="false" 
            :showPerPage="false" 
            class="mt-2 pt-75" />
        </b-card>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import Ripple from 'vue-ripple-directive'

import { apiToDate } from '@core/utils/filter';
// Alert
import AlertService from '@/common/alert.service'

// Custom Components
import MusteriDetail from './MusteriDetail.vue'
import KullaniciList from '../kullanici-yonetim/KullaniciList.vue'

export default {
  components: {
    MusteriDetail,
    KullaniciList,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      musteriData: {},
    }
  },
  methods: {
    fetchMusteri() {
      this.$store.dispatch('yonetimMusteri/fetchMusteri', { id: this.$router.currentRoute.params.id })
        .then(response => {
          if (response.statusCode === 200) {
            response.result.dtOlusTarih = apiToDate(response.result.dtOlusTarih);
            this.musteriData = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
    setKullaniciFilters() {
      this.$store.commit('refreshKullaniciFilter', { musteriId: parseInt(this.$router.currentRoute.params.id, 10) })
    },
  },

  created() {
    this.fetchMusteri();
    this.setKullaniciFilters();
  },
  
  beforeDestroy() {
    // Sayfadan çıktıktan sonra filtreyi sıfırla
    this.$store.commit('refreshKullaniciFilter', { musteriId: null });
  },
}
</script>

<style>

</style>
